
module.exports = {
  en: {
    path: 'en',
    locale: 'English'
  },
  de: {
    path: 'de',
    locale: 'German',
    default: true

  }
}
