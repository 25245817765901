import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons';
import '../styles/react-flags-select.css';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import LocalizedLink from "./int/LocalizedLink";
import {Location, navigate} from "@reach/router";
import Flag from 'react-world-flags'
import LocalizedFlagLink from "./LocalizedFlagLink";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

const StyledFooter = styled.footer`
text-align:center;
 align-items: center;
  margin-left:auto;
  margin-right:auto;
  justify-content:center;
  width:80%;
  display:flex;
  margin-top:40px;
  margin-bottom:10px;
  height:80px;

         @media screen and (max-width: 1295px) {
}


`

const FooterBox = styled.div`
 width:auto;
 text-align:left;
 height:65px;
 margin-right:4%;
 padding-right:4%;
 border-right:solid 1px rgba(68, 68, 68, .1);
padding-top:8px;

  @media screen and (max-width: 1295px) {
display:none;
   }

`

const SocialBox = styled.div`
  display: flex;

   border-right:solid 1px rgba(68, 68, 68, .1);
    padding-right:4%;

      @media screen and (max-width: 1295px) {
border:none;
justify-content:space-between;
width:100%;
    padding-right:0%;
}

`

function onSelectFlag(countryCode){
  let c = countryCode.toLowerCase();
  console.log(c + ' test c variable');
  if (c === 'de')
    navigate(
      "/",
      { replace: true }      )
  if (c === 'gb')
    navigate('/en',  { replace: true });
}


const FlagDiv = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
width:100px;
padding-left:10px;

.flag-select__options {
top: -100px;
width: 100px;

}

.flag-select__btn::after {

display:none;
}

ul{
margin-left:0px;
}

`

const StyledLink = styled(LocalizedLink)`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
color:rgb(68, 68, 68);
display:block;
font-size: 0.9em;
width:auto;
margin-top:2px;


`

const Copyright = styled.div`
  margin-bottom:2%;
  text-align:center;
  letter-spacing: 4px;
  width:100%;
  color:lightgrey;
  margin-left:auto;
  margin-right:auto;
  font-family: "Theano Didot", Regular;
`

const RDiv = styled.div`
width:100% !important;
`

const Invisible = styled.div`
opacity:0;
`

const FlagsSelect = styled(ReactFlagsSelect)`
color:rgb(68, 68, 68);


`



const ConditionalSignUp = (props) => {

  if (!props.isBefore) {
    return <StyledLink to="/buchen"><FormattedMessage id="home_nav_10" /></StyledLink>

  }

  return <StyledLink to="/buchen"><FormattedMessage id="home_nav_12" /></StyledLink>

}

const ConditionalLogin = (props) => {

  if (!props.isBefore) {
    return <StyledLink to="/user">DASHBOARD</StyledLink>

  }
  return <StyledLink to="/user">DASHBOARD</StyledLink>
}



class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBefore:false
    }
  }


  componentDidMount(){
    const isBeforee = () =>{
      if(window.localStorage.getItem('expires_at')!==null) {
        let now = moment().toISOString()
        return moment(now).isBefore(window.localStorage.getItem('expires_at')); // true
      }
      return false;
    }

    this.setState({
      isBefore: isBeforee
    })
  }

  render() {
    return(
    <StyledFooter id={'foot'}>
      <FooterBox>

      </FooterBox>
      <FooterBox>
        <StyledLink to="/kontakt"><FormattedMessage id="home_nav_13"/></StyledLink>

        <StyledLink to="/impressum"><FormattedMessage id="home_nav_8"/></StyledLink>
      </FooterBox>
      <FooterBox>
        <StyledLink to="/agb"><FormattedMessage id="home_nav_7"/></StyledLink>
        <StyledLink to="/datenschutz"><FormattedMessage id="home_nav_9"/></StyledLink>

      </FooterBox>
      <SocialBox>
        <SocialIcon style={{height: 55, width: 55}} url="https://www.instagram.com/vilhelm7berlin/"
                    fgColor="lightgrey" bgColor="#fff"/>
        <SocialIcon style={{height: 55, width: 55}} url="https://www.facebook.com/Vilhelm7Berlin/" fgColor="lightgrey"
                    bgColor="#fff"/>
        <SocialIcon style={{height: 55, width: 55}} url="https://wa.me/491732070173" network="whatsapp"
                    fgColor="lightgrey" bgColor="#fff"/>
        <SocialIcon style={{height: 55, width: 55}} url="mailto:info@vilhelm7.de" network="email" fgColor="lightgrey"
                    bgColor="#fff"/>
      </SocialBox>
      <FlagDiv>

        <FlagsSelect
          defaultCountry="GB"
          customLabels={{"DE": 'Deutsch',"GB": 'English'}}
          searchable={false}
          showSelectedLabel={false}
          disabled={false}
          countries={["GB", "DE"]}
          selectedSize={18}
          optionsSize={14}
          onSelect={onSelectFlag} />

      </FlagDiv>

    </StyledFooter>

    )
  }
}


export default Footer
