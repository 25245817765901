import React from 'react'
import { Link } from 'gatsby'
import { injectIntl, intlShape } from 'react-intl'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import locales from '../../constants/locales'


const LocalizedAnchorLink = ({ to, intl: { locale }, ...props }) => {
  const path = locales[locale].default ? to : `/${locale}${to}`

  return <AnchorLink {...props} to={path} />
}

export default injectIntl(LocalizedAnchorLink)
