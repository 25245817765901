import * as React from 'react'
import styled from '@emotion/styled';
import { push as Menu } from 'react-burger-menu'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { FormattedMessage } from 'react-intl';
import ReactFlagsSelect from 'react-flags-select';
import '../../styles/react-flags-select.css';

import Headroom from 'react-headroom';
const menu_icon = require('../../../assets/images/menu_three.png')
const logo = require('../../../assets/images/V7_000_Logo.png')
const logoMenu = require('../../../assets/images/logo.png')
const arrowDown = require('../../../assets/images/expand-button.png')
const moreOptions = require('../../../assets/images/elipsis.png')



//import { heights, dimensions, colors } from '../styles/variables'
//import Container from './Container'
import {Link} from "gatsby";
import LocalizedLink from "../int/LocalizedLink";
import {navigate} from "@reach/router";
import LocalizedAnchorLink from '../int/LocalizedAnchorLink'
const StyledHeadroom = styled(Headroom)`

display:none;
z-index:999;
position:relative;
  @media screen and (max-width: 1295px) {
display:block;

   }
`



const FlagsSelect = styled(ReactFlagsSelect)`
font-family: "Theano Didot", Regular;
letter-spacing: 2px;
color:rgb(68, 68, 68);



`

const A = styled.div`
display:block;
font-family: "Theano Didot", Regular;

text-align:center;
position:relative;
z-index:999999;
transition:all ease .7s;
padding-top:0.4em;
padding-bottom:0.4em;
letter-spacing:2px;


:hover{
color:black;
  letter-spacing: 0px;



}
`


const ABorder = styled.a`
display:block;
font-family: "Theano Didot", Regular;

text-align:center;
position:relative;
z-index:999999;
transition:all ease .7s;
padding-top:0.4em;
padding-bottom:0.4em;
letter-spacing:2px;


:hover{
color:black;
  letter-spacing: 0px;



}
`






var styles = {

  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    right:'1em'
  },
  bmCross: {
    background:'lightgrey',

  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top:0,
    zIndex:99999999,
    overflowY: 'overlay'
  },
  bmMenu: {
    background: 'white',
    padding: '0 20px 0',
    fontSize: '1.15em',
    top:0,
    zIndex:99999999,
    minWidth:'300px !important'

  },
  bmMorphShape: {
    fill: 'white',
    zIndex:99999999

  },
  bmItemList: {
    padding: '8px',
    top:0
  },
  bmItem: {
    textAlign:'center',



  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top:0,
    zIndex:99999999

  }
}

const Img = styled.img`
margin-top:1em;
margin-bottom: 1.5em;
max-width:227px;
`


const StyledHeader = styled.header`
  height: 58px;
position:relative;
z-index:9999999;
  background-color: white;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.157);
  display:flex;
  align-items:center;



`






const MenuIcon = styled.div`
cursor:pointer;
width: 44px;
height:auto;
opacity:0.97;
padding-left:0.5rem;
;
.stripe-1{fill:var(--textNormal);}

`

const MenuDiv = styled.div`

cursor:pointer;
height:100%;
width:auto;
display:flex;
align-items:center;
position:absolute;
right:0;
z-index:99999999;
border-radius:2px;
padding-right:1.3rem;
padding-left:1rem;
border-right: 1px solid rgba(68,68,68,0);


transition:all .7s ease;

:hover{
background:rgba(68,68,68,.17);
border-right: 1px solid rgba(68,68,68,.1);
}

`
const LogoDiv = styled.div`
height:100%;
justify-content:center;
width:100%;
text-align:center;
position:absolute;


`
const Logo = styled.div`
 top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
cursor:pointer;
width: 37px;
position: absolute;
height:auto;
left:50%;
right:24px;
.polygon{fill:var(--textNormal);}

`

const StyledMenu = styled(Menu)`
font-family: "Theano Didot", Regular;
letter-spacing:2px;
z-index:9999999999;

.flag-options{
//right:-80px;
background:white !important;
opacity:1 !important;


}

.flag-select__options{
top: -100px;
margin-left:0px;
width:200px !important;
left:-50px;

}

`

const ArrowImg = styled.img`
width:5.5%;
position:absolute;
margin-top:7.5px;
margin-left:6px;
transition:all ease 1s;

`

const ArrowImg_2 = styled.img`
margin-top:3px;
width:10%;
transition:all ease 1s;


`

const StyledLink = styled(LocalizedLink)`
color:black;
opacity:1;


`

const StyledAnchorLink = styled(LocalizedAnchorLink)`
color:black;
opacity:1;

`

const StyledLink_2 = styled(LocalizedLink)`
color:black;
opacity:1;


`


const StyledLink_3 = styled.a`
color:black;
opacity:1;


`

const BorderTopBottom = styled.div`
border-top:1px solid rgba(68,68,68,.2);
border-bottom:1px solid rgba(68,68,68,.2);
`

const BorderBottom = styled.div`
border-bottom:1px solid rgba(68,68,68,.2);
`

const HeadroomDiv = styled.div`
`

function onSelectFlag(countryCode){
  let c = countryCode.toLowerCase();
  //console.log(c + ' test c variable');
  if (c === 'de')
    navigate(
      "/",
      { replace: true }      )
  if (c === 'gb')
    navigate('/en',  { replace: true });
}


export class BurgerMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false,
      roomsClosed:true,
      optionsClosed:true,
      rotation: 0,
      rotation_2: 0

    }
    this.rotate = this.rotate.bind(this);
    this.rotate_2 = this.rotate_2.bind(this);

  }

  rotate(){
    let newRotation = this.state.rotation;
    if(newRotation === 180){
      newRotation -= 180;
    }
else
    if(newRotation === 0){
      newRotation += 180;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  rotate_2(){
    let newRotation = this.state.rotation_2;
    if(newRotation === 0){
      newRotation += 270;
    }
    else
    if(newRotation === 270){
      newRotation -= 270;
    }
    this.setState({
      rotation_2: newRotation,
    })
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu () {
    this.setState({menuOpen: false})
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu () {
    this.setState({menuOpen: !this.state.menuOpen})
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleRooms () {
    this.setState({roomsClosed: !this.state.roomsClosed});
    this.rotate();
  }

  toggleOptions () {
    this.setState({optionsClosed: !this.state.optionsClosed});
    this.rotate_2();

  }


  render () {
    return (
      <HeadroomDiv>
        <StyledHeadroom>
          <StyledHeader>
            <MenuDiv onClick={() => this.toggleMenu()}>
              <MenuIcon>
                <svg id="menu" data-name="menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.15 26.32">
                  <defs>
                  </defs>
                  <title>menu</title>
                  <rect className="stripe-1" width="85.15" height="5.4"/>
                  <rect className="stripe-1" y="10.46" width="85.15" height="5.4"/>
                  <rect className="stripe-1" y="20.92" width="85.15" height="5.4"/>
                </svg>
              </MenuIcon>
            </MenuDiv>

            <Logo>
              <svg id="logo-small" data-name="logo-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.03 78.75">
                <title>v7-logo</title>
                <polygon className="polygon" points="76.03 0 71.27 0 38.02 68.91 4.76 0 0 0 38 78.75 38.03 78.75 76.03 0"/>
                <polygon className="polygon" points="61.6 0 38.06 48.79 14.43 0 9.67 0 38.02 58.73 66.36 0 61.6 0"/>
                <polygon className="polygon" points="21.42 3.9 50.43 3.9 35.79 34.24 38.17 39.17 57.07 0 19.64 0 21.42 3.9"/>
              </svg>

            </Logo>




          </StyledHeader>
        </StyledHeadroom>
        <StyledMenu
          right
          disableAutoFocus
          customBurgerIcon={ false }
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          styles={styles}
          pageWrapId={ "page-wrap" }
        >
          <Img onClick={() => this.closeMenu()} src={logoMenu}/>
         <BorderTopBottom> <StyledLink_2 to={'/'}> <A onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_1" /></A> </StyledLink_2>
         </BorderTopBottom>
         <BorderBottom>
          <A onClick={() => this.toggleRooms()}><FormattedMessage id="home_nav_2" />
            <ArrowImg style={{transform: `rotate(${this.state.rotation}deg)`}} src={arrowDown}/>
          </A>
         </BorderBottom>

          <SlideDown  closed={this.state.roomsClosed} className={'css-2289437'}>
            <div style={{background:'rgba(68,68,68,.04)'}}>
            <StyledLink to={'/interior'}> <A>INTERIOR</A> </StyledLink>
            <StyledAnchorLink to={'/wohnen#studio'}> <A onClick={() => this.closeMenu()} >STUDIO</A> </StyledAnchorLink>
            <StyledAnchorLink   to={'/wohnen#studio-plus'}> <A onClick={() => this.closeMenu()} >STUDIO PLUS</A> </StyledAnchorLink>
           <BorderBottom>  <StyledAnchorLink to={'/wohnen#suite'}> <A onClick={() => this.closeMenu()} >SUITE</A> </StyledAnchorLink>
           </BorderBottom>
            </div>


          </SlideDown>
          <BorderBottom>  <StyledLink to={'/buchen'}> <A onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_3" /></A> </StyledLink> </BorderBottom>
          <BorderBottom>           <StyledLink_3 href="https://vilhelm7coworking.de/"><A>COWORKING</A></StyledLink_3>
</BorderBottom>
          <BorderBottom>  <StyledLink to={'/breakfast'}> <A onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_4" /></A> </StyledLink></BorderBottom>
            <BorderBottom>  <StyledLink to={'/lage'}> <A  onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_5" /></A></StyledLink></BorderBottom>
              <BorderBottom>  <StyledLink_2 to={'/extras'}> <A  onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_6" /></A> </StyledLink_2></BorderBottom>
                <BorderBottom>  <A onClick={() => this.toggleOptions()}>
            <ArrowImg_2 style={{transform: `rotate(${this.state.rotation_2}deg)`}} src={moreOptions}/>
                </A></BorderBottom>


          <SlideDown closed={this.state.optionsClosed} className={'css-2289437'}>
<div style={{background:'rgba(68,68,68,.04)'}}>
            <StyledLink to={'/kontakt'}> <A onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_13" /></A> </StyledLink>
            <StyledLink to={'/impressum'}> <A  onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_8" /></A> </StyledLink>
            <StyledLink to={'/agb'}> <A onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_7" /></A> </StyledLink>
            <StyledLink to={'/datenschutz'}>  <A onClick={() => this.closeMenu()}><FormattedMessage id="home_nav_9" /></A> </StyledLink>
</div>
          </SlideDown>
          <A>
          <FlagsSelect

            defaultCountry="DE"
            countries={["DE", "GB"]}
            customLabels={{"DE": "DEUTSCH","GB": "ENGLISH"}}
            showSelectedLabel={false}
            onSelect={onSelectFlag}
          >

          </FlagsSelect>
          </A>




        </StyledMenu>
      </HeadroomDiv>
    )
  }
}
