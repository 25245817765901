import * as React from 'react'
import Helmet from 'react-helmet'
import {StaticQuery, graphql, Link} from 'gatsby'
import HomeSlider from "../components/HomeSlider";
//import { StickyContainer, Sticky } from 'react-sticky';
import styled from '@emotion/styled'
import Headroom from 'react-headroom';
require('../styles/global.css');
//import ScrollHead from './../components/ScrollHead';
import ScrollTop from "react-scrolltop-button";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FormattedMessage } from 'react-intl';
import SimpleReactLightbox from 'simple-react-lightbox'

import { IntlProvider, addLocaleData } from 'react-intl'
import { CookieBanner } from '@palmabit/react-cookie-law';
//import scrollToComponent from 'react-scroll-to-component'


import 'airbnb-browser-shims';


import 'modern-normalize'
import '../styles/normalize'
//import Navigation from "../components/Navigation";
//import Plx from 'react-plx';
//import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import Footer from "../components/Footer";
//import HeaderMobile from './../components/HeaderMobile';
//import CookieBar from "../components/cookie/CookieBar";
import {BurgerMenu} from "../components/menu/BurgerMenu";
//import {Dates} from "../components/booking/BookingDates";
//import LocalizedLink from "../components/int/LocalizedLink";
//import CookieConsent from "react-cookie-consent";
import { fonts } from '../styles/variables'
//import PageTransition from 'gatsby-plugin-page-transitions';


// Locale data
const enData = require('react-intl/locale-data/en')
const deData = require('react-intl/locale-data/de')



// Translations
const en = require('../locales/en/en.json')
const de = require('../locales/de/de.json')




const messages = { de, en };

addLocaleData([...deData, ...enData])


const CookieWrap = styled.div`
.react-cookie-law-dialog{
border: 1px solid #cacaca !important;
padding: 1em 2em 1.5em 2em !important;
background:rgba(248, 248, 248) !important;
z-index: 9999999 !important;

@media screen and (max-width: 700px) {
padding: 1em 2em 2em 2em !important;

}
}
.react-cookie-law-dialog{
bottom: 0px;
top: auto !important;}

.react-cookie-law-accept-btn{
color:#006400 !important;
border-radius:25px !important;
    background: #f2f2f2 !important;
    border: 1px solid #cdcdcd !important;
min-width:180px !important;
    padding: 7px !important;
    font-family:${fonts.engraversBold} !important;
          text-transform: lowercase !important;
              font-size:1.2em !important;





}
.react-cookie-law-msg{
    font-family:${fonts.engraversBold} !important;
    font-size:1.2em !important;
      text-transform: lowercase !important;


}
.react-cookie-law-container{
position:relative;
}

.react-cookie-law-policy{
span{
display:none;
}
}
.react-cookie-law-decline-btn{
position: absolute;
    background: #f2f2f2 !important;
    border: 1px solid #cdcdcd !important;color:rgb(68,68,68) !important;
border-radius:25px !important;
    padding: 7px !important;
    bottom:0px;


left: 0px;

@media screen and (max-width: 700px) {
left:2em;
bottom:0.4rem;
}
}

label{
color:rgb(68,68,68) !important;
}
@media screen and (max-width: 1200px) {
}
`

const Copyright = styled.div`
  text-align:center;
  letter-spacing: 4px;
  width:100%;
  color:lightgrey;
  margin-left:auto;
  margin-right:auto;
  font-family: "Theano Didot", Regular;
  margin-bottom:15px;




    @media screen and (max-width: 1295px) {
      margin-bottom:0px;

}

@media screen and (max-width: 700px) {

}
`

const ScrollUp = styled(IoIosArrowRoundUp)`
width:32px;
height:32px;
color:rgb(68,68,68);
opacity:0.7;


`

const ScrollToTop = styled(ScrollTop)`
:hover{
${ScrollUp} {
opacity:1;
}

}



`

const ScrollWrapper = styled.div`

.react-scrolltop-button-mobile{
background:rgba(255,255,255,.8) !important;
border:1px solid lightgrey !important;
margin-bottom:35px;
right:3% !important;
padding-left:0px !important;
padding-right:0px !important;
padding-top:1px !important;
padding-bottom:0px !important;
z-index:999991;
  overflow: hidden;

height:50px;
width:50px;

:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(68,68,68,.2);
  transition: all .3s;
  border-radius: 50%;
  z-index: -1;

}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 50%;
  z-index: -2;

}

:hover {
  color: #fff;


}

:hover:before {
  height: 100%;
}




      @media screen and (max-width: 1295px) {

padding:0px !important;
height:52px;
width:52px;
margin-bottom:75px;

}

}
`


//favIcons



const apple_touch = require('../../assets/images/favicons/apple-icon.png');
const fav32 = require('../../assets/images/favicons/favicon-32x32.png');
const fav16 = require('../../assets/images/favicons/favicon-16x16.png');
//const fav_saf = require('../../assets/images/favicons/safari-pinned-tab.svg');



type StaticQueryProps = {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
}

const IndexLayout: React.FC = ({ locale,children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {

          siteMetadata {
            title
              description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <SimpleReactLightbox>
      <IntlProvider locale={locale} messages={messages[locale]}>
      <div id="outer-container">
        <BurgerMenu></BurgerMenu>

        <main id="page-wrap">

        <LayoutRoot>
        <Helmet

          title={'Vilhelm7 Berlin Residences | Hotel, Apartments | Berlin Kreuzberg'}
          meta={[
            { name: 'description', content: 'V7 ist Hotel, Apartmenthaus und Wohnanlage in Einem. Im Herzen Kreuzbergs liegt an der Grenze nach Mitte ein kleines, feines und individuell gestaltetes Hotel. Ankommen und wohlfühlen. Boutique Hotel in der Wilhelmstr 7 Berlin Kreuzberg V7 Vilhelm7 Apartments Hotel Zimmer in Berlin Rooms Suite günstig Discount Übernachtung Zentrum. ' },
            { name: 'keywords', content: 'Vilhelm7, Apartments, Hotel, Zimmer, Berlin, room, Kreuzberg, Mitte,  buchen, city, center, zentrum'},
            {name: 'page-type', content: 'Hotel, Apartments'},
            { name:'copyright', content:"https://www.vilhelm7.de"},
            { name:'X-UA-Compatible', content:"IE=edge"},


          ]}
          link={[
            {
              rel: "apple-touch-icon", sizes: "180x180", href:apple_touch},
            {rel: "icon", type: "image/png", sizes:"32x32", href: apple_touch},
            {rel:"icon",type:"image/png", sizes:"16x16", href: apple_touch },

          ]}
        />



          <CookieWrap>
            <CookieBanner
              message={<FormattedMessage id="cookie_text_1"/>}
              wholeDomain={false}
              onAccept = {() => {}}
              onAcceptPreferences = {() => {}}
              onAcceptStatistics = {() => {}}
              onAcceptMarketing = {() => {}}
              showDeclineButton={true}
              privacyPolicyLinkText={<FormattedMessage id="cookie_text_2"/>}
              necessaryOptionText={<FormattedMessage id="cookie_text_5"/>}
              statisticsOptionText={<FormattedMessage id="cookie_text_6"/>}
              preferencesOptionText={<FormattedMessage id="cookie_text_7"/>}
              marketingOptionText={<FormattedMessage id="cookie_text_8"/>}
              acceptButtonText={<FormattedMessage id="cookie_text_3"/>}
              declineButtonText={<FormattedMessage id="cookie_text_9"/>}
            />
          </CookieWrap>


          <ScrollWrapper>
          <ScrollToTop
            distance={200}
            breakpoint={2400}
            className="scroll-up"
            speed={1600}
            target={0}
            icon={<ScrollUp/>}
          />
</ScrollWrapper>



 <LayoutMain>{children}</LayoutMain>
        <Footer></Footer>
        <Copyright>&#169;2023 7Arte Group UG</Copyright>

      </LayoutRoot>
        </main>
      </div>
      </IntlProvider>
      </SimpleReactLightbox>
    )}
  />
)




export default IndexLayout
